import { render, staticRenderFns } from "./XwStatus.vue?vue&type=template&id=6e3606ee&scoped=true"
import script from "./XwStatus.vue?vue&type=script&lang=js"
export * from "./XwStatus.vue?vue&type=script&lang=js"
import style0 from "./XwStatus.vue?vue&type=style&index=0&id=6e3606ee&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e3606ee",
  null
  
)

export default component.exports
import { render, staticRenderFns } from "./XwForm.vue?vue&type=template&id=34c0b618&scoped=true"
import script from "./XwForm.vue?vue&type=script&lang=js"
export * from "./XwForm.vue?vue&type=script&lang=js"
import style0 from "./XwForm.vue?vue&type=style&index=0&id=34c0b618&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34c0b618",
  null
  
)

export default component.exports